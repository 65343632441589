import { action, computed, observable, runInAction, makeObservable } from "mobx";
import { Tools } from "../tools";

export default class AppMainStore {
  constructor() {
    makeObservable(this, {
      activeBusyFuncs: observable,
      IsDyslexicTheme: observable,
      navMenuOpen: observable,
      mobileSearchListOpen: observable,
      hasDeterminedLocation: observable,
      initialAddressText: observable,
      isOld: observable,
      loadFail: observable,
      isBusy: computed,
      setBusy: action,
      setMobileSearchListOpen: action,
      setNavMenuOpen: action,
      userLocationCallBackError: action,
      checkSupportedBrowser: action.bound
    });


  }

  bindStores({ electoratesStore }) {
    this.electoratesStore = electoratesStore;
  }

  activeBusyFuncs = 0;
  IsDyslexicTheme = false;
  navMenuOpen = false;
  mobileSearchListOpen = false;
  hasDeterminedLocation = false;
  initialAddressText = "";
  isOld = null;
  loadFail = null;

  get isBusy() {
    return this.activeBusyFuncs != 0;
  }

  searchResultComponentRefs = {};
  searchResultsComponentRef = null;

  setBusy(busy) {
    if (busy) {
      this.activeBusyFuncs++;
    } else {
      this.activeBusyFuncs--;
    }
  }

  setMobileSearchListOpen(open) {
    this.mobileSearchListOpen = open;
  }

  setNavMenuOpen(navMenuOpen) {
    this.navMenuOpen = navMenuOpen;
  }

  bindHistory(history) {
    this.history = history;
    const addressQueryString =
      history.location.search.match(/address=([^&/]*)/);
    if (addressQueryString) {
      this.initialAddressText = decodeURI(addressQueryString[1]);
    }
  }

  determineUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.userLocationCallback,
        this.userLocationCallBackError
      );
    }
  }

  userLocationCallBackError = () => {
    this.hasDeterminedLocation = false;
  };

  checkSupportedBrowser() {
    if (Tools.isUnsupportedBrowser()) {
      this.isOld = true;
    }
  }

  flagLoadFailed() {
    runInAction(() => {
      this.loadFail = true;
    });
  }
  flagLoadSucceeded() {
    runInAction(() => {
      this.loadFail = false;
    });
  }
}
