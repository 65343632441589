import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import showMapKey from "../../../static/images/show-map-key.svg";
import { ElectorateType } from "../../enums";
import { Tools } from "../../tools";
import { ElectorateStyles } from "../../utils/constants";
import SelectionList from "./selection-list";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.contrastText,
    margin: "0px !important",
    boxShadow: "none",
  },
  moduleToolbar: {
    minHeight: "initial",
  },

  heading: {
    color: theme.palette.navy.main,
    paddingTop: "16px",
    paddingBottom: "18px",
    width: "calc(100% - 32px)",
    height: "24px",
    alignItems: "center",
    display: "flex",
    backgroundColor: theme.palette.primary.contrastText,
    fontWeight: "600",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      ...theme.components.MuiTypography.styleOverrides.h6,
    },
  },
  expansionSummary: {
    padding: "0px 16px !important",
  },
  expansionSummaryTitle: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  expansionSummaryIcon: {
    marginRight: "8px",
  },
  expansionDetails: {
    display: "block",
    padding: "0px 16px",
    "& > h5:first-of-type": {
      paddingTop: "0px",
    },
  },
}));

const ElectorateSelector = ({ electoratesStore }) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    if (Tools.breakpoints.up("md")) {
      setExpanded(!expanded);
    }
  };

  const handleRegionChange = (selection, boundarySetIndex) =>
    electoratesStore.setSelectedRegion(selection, boundarySetIndex);

  const handleDistrictChange = (selection, boundarySetIndex) =>
    electoratesStore.setSelectedDistrict(selection, boundarySetIndex);

  const classes = useStyles();

  const { boundarySets } = electoratesStore;
  const desktopView = Tools.breakpoints.up("md");

  return (
    <Accordion
      className={classes.container}
      square
      expanded={expanded || !desktopView}
      onChange={toggleExpanded}
    >
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={desktopView ? <ExpandMore /> : null}
        id="electorate-selector"
      >
        {!expanded && (
          <img
            alt="layers icon"
            className={classes.expansionSummaryIcon}
            src={showMapKey}
          />
        )}
        <Typography
          variant="h1"
          className={classes.heading + " " + classes.expansionSummaryTitle}
        >
          {expanded ? "State Electoral Boundaries" : "Electoral Areas"}{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetails}>
        {boundarySets.map((boundarySet, boundarySetIndex) => (
          <React.Fragment key={boundarySet.stage}>
            <Typography variant="h2" className={classes.heading}>
              {boundarySet.boundarySetName}
            </Typography>
            <SelectionList
              heading="Upper House Region"
              name="region"
              id={"region" + boundarySet.stage}
              onChange={(selection) => {
                handleRegionChange(selection, boundarySetIndex);
              }}
              style={ElectorateStyles.regions[boundarySetIndex]}
              listItems={boundarySet.regions}
              title={boundarySet.boundarySetName + " - Regions"}
              selectedItem={boundarySet.selectedRegion}
              enabled={boundarySet.regions.length > 0}
              selectedElectorateType={ElectorateType.Region}
              onMouseOver={() => {
                boundarySet.toggleHighlightSelectedRegion(true);
              }}
              onMouseLeave={() => {
                boundarySet.toggleHighlightSelectedRegion(false);
              }}
              showAll={boundarySet.showAllRegions}
              showLabels={boundarySet.showAllRegionLabels}
              onToggleShowAll={boundarySet.toggleShowAllRegions}
              onToggleShowLabels={boundarySet.toggleShowAllRegionLabels}
            />
            <SelectionList
              heading="Lower House District"
              name="district"
              id={"district" + boundarySet.stage}
              onChange={(selection) => {
                handleDistrictChange(selection, boundarySetIndex);
              }}
              style={ElectorateStyles.districts[boundarySetIndex]}
              listItems={boundarySet.districts}
              title={boundarySet.boundarySetName + " - Districts"}
              selectedItem={boundarySet.selectedDistrict}
              enabled={boundarySet.districts.length > 0}
              selectedElectorateType={ElectorateType.District}
              onMouseOver={() => {
                boundarySet.toggleHighlightSelectedDistrict(true);
              }}
              onMouseLeave={() => {
                boundarySet.toggleHighlightSelectedDistrict(false);
              }}
              showAll={boundarySet.showAllDistricts}
              showLabels={boundarySet.showAllDistrictLabels}
              onToggleShowAll={boundarySet.toggleShowAllDistricts}
              onToggleShowLabels={boundarySet.toggleShowAllDistrictLabels}
            />
            <Divider />
          </React.Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

ElectorateSelector.propTypes = {
  electoratesStore: PropTypes.object,
  classes: PropTypes.object,
};

export default inject("electoratesStore")(observer(ElectorateSelector));
