import { Cancel, KeyboardArrowDown } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { action, computed, makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { Tools } from "../../tools";

const styles = (theme) => ({
  selectMain: {
    ...theme.components.MuiTypography.styleOverrides.body2,
    borderRadius: "4px",
    border: "solid 2px #37444E",
    backgroundColor: theme.palette.primary.contrastText,
    paddingLeft: "8px",
    marginRight: "0px",
    marginTop: "16px",
    color: theme.palette.navy.dark,
    fontWeight: "bold",
    borderColor: "rgba(55, 68, 78, 0.4)",
    ["& .MuiSelect-select"]: {
      padding: "7px 0 7px",
    },
    ["& .MuiNativeSelect-select"]: {
      padding: "7px 0 7px",
    },
  },
  title: {
    color: theme.palette.grey.grey600,
    minWidth: "160px",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      ...theme.components.MuiTypography.styleOverrides.body2,
    },
  },
  titleContainer: {
    paddingTop: "6px",
  },
  chevron: {
    color: theme.palette.navy.main + " !important",
  },
  disabledChevron: {
    color: "rgba(0, 0, 0, 0.38)",
  },
  selectContainer: {
    backgroundColor: theme.palette.primary.contrastText,
    paddingBottom: "24px",
  },
  listContainer: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "inherit",
    ["& div"]: {
      overflowX: "auto",
      pointerEvents: "initial",
    },
    ["& li"]: {
      background: "inherit !important",
      display: "block",
      pointerEvents: "none",
    },
  },
  iconClass: {
    cursor: "pointer",
    paddingLeft: "2px",
    paddingRight: "2px"
  },
  windowTitle: {
    color: theme.palette.grey.grey600,
  },
  itemList: {
    display: "inline-grid",
    gridAutoFlow: "column",
    listStyle: "none",
    paddingInlineStart: "0px",
    cursor: "default",
    padding: "0px",
    ["& li"]: {
      width: "fit-content",
      padding: "3px 32px 3px 0px",
      whiteSpace: "nowrap",
      display: "block",
      pointerEvents: "none",
      color: theme.palette.grey.grey600,
      ["& hr"]: {
        width: "60px",
        marginTop: "-0.5em",
        borderStyle: "solid",
        borderRadius: "4px",
        borderWidth: "1px",
        marginLeft: "20px",
      },
      ["&.item"]: {
        pointerEvents: "auto",
        overflowX: "visible",
        cursor: "pointer",
      },
      ["&.item:hover"]: {
        backgroundColor: theme.palette.secondary.main + " !important",
        color: theme.palette.secondary.contrastText,
        cursor: "pointer",
      },
    },
  },
  formControl: {
    width: "100%",
  },
  cancelSelectionBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  layerSwitchContainer: {
    textAlign: "right",
  },
  deviceDrawer: {
    marginTop: "200px",
    overflow: "visible",
    border: "none",
    [theme.breakpoints.down("md")]: {
      width: "400px",
      marginTop: "280px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      marginTop: "188px",
    },
  },
  desktopDrawer: {
    marginTop: "65px",
    width: "400px",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "black",
  },
  iconDivider: {
    border: "1px #dddddd",
    height: "24px",
    marginLeft: "3px",
    width: "1px"
  },
  helperLabel: {
    display: "none",
  },
  toggle: {
    ["& .Mui-disabled"]: {
      color: "#BDBDBD",
    },
  },
});

class SelectionList extends React.Component {
  constructor(props) {
    super(props);

    makeObservable(this, {
      showList: observable,
      hovered: observable,
      focused: observable,
      sortedItems: computed,
      selectedItem: computed,
      hasSelection: computed,
      customInputGotFocus: action,
      hideCustomSelectList: action,
      itemSelected: action,
      onMouseOver: action.bound,
      onMouseLeave: action.bound,
    });

    this.inputRef = React.createRef();
    this.selectInfoRef = React.createRef();
  }

  showList = false;
  hovered = false;
  focused = false;

  get sortedItems() {
    const sortedList = this.props.listItems
      .slice()
      .sort((el1, el2) => {
        if (el1.electorateName > el2.electorateName) return 1;
        if (el1.electorateName < el2.electorateName) return -1;
        return 0;
      })
      .map((item) => {
        let name = item.electorateName;
        if (
          name.substring(name.length - item.electorateType.length) !=
          item.electorateType
        ) {
          name += " " + item.electorateType;
        }
        return { ...item, electorateName: name };
      });
    return sortedList;
  }

  get selectedItem() {
    let item = this.props.selectedItem;
    if (item) {
      let name = item.electorateName;
      if (
        name.substring(name.length - item.electorateType.length) !=
        item.electorateType
      ) {
        name += " " + item.electorateType;
      }
      return { ...item, electorateName: name };
    } else {
      return item;
    }
  }

  get hasSelection() {
    return !!this.props.selectedItem && !!this.props.selectedItem.electorateId;
  }

  componentWillUnmount() {
    const { onMouseLeave } = this.props;
    if (onMouseLeave) {
      onMouseLeave();
    }
  }

  customInputGotFocus() {
    this.showList = true;
  }

  hideCustomSelectList() {
    this.showList = false;
  }

  itemSelected() {
    this.showList = false;
    this.props.onChange();
  }

  handleSelectionChange = (itemId) => {
    if (this.props.onChange) {
      const item = this.props.listItems.find(
        (item) => item.electorateId == itemId
      );
      this.props.onChange(item);
    }
  };

  handleCancelClick = () => {
    this.props.onChange({});
  };

  handleInfoClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleToggleShowAll = () => {
    if (this.props.onToggleShowAll) {
      this.props.onToggleShowAll();
    }
  };

  handleToggleShowLabels = () => {
    if (this.props.onToggleShowLabels) {
      this.props.onToggleShowLabels();
    }
  };

  onMouseOver = () => {
    if (Tools.breakpoints.up("md")) {
      const { onMouseOver } = this.props;
      if (onMouseOver) {
        this.hovered = true;
        onMouseOver();
      }
    }
  };

  onMouseLeave = () => {
    if (Tools.breakpoints.up("md")) {
      const { onMouseLeave } = this.props;
      if (onMouseLeave) {
        this.hovered = false;
        onMouseLeave();
      }
    }
  };

  render() {
    const {
      classes,
      heading,
      name,
      id,
      style,
      title,
      enabled,
      placeHolderText,
      selectedElectorateType,
      showAll,
      showLabels,
    } = this.props;
    const numListRows = Math.round(Math.sqrt(this.sortedItems.length) * 2);
    const supportsCustomListSelector =
      !!window.CSS &&
      window.CSS.supports("grid-template-rows", "repeat(3, auto)");
    const isMobileView = Tools.breakpoints.down("xs");
    const isDesktopView =
      Tools.breakpoints.up("md") && supportsCustomListSelector;
    let sortedListItems = [];
    let border = {};
    if (style) {
      border.borderStyle = "none";
      border.borderColor = style.colour;
    }

    let iconStyle = "";
    if (style && this.hasSelection) {
      iconStyle = "color: '" + style.colour + "'";
    }

    const inputStyle = {
      paddingRight: isMobileView ? "0" : null
    };
    if (style && this.hasSelection) {
      inputStyle.borderColor = style.colour;
      inputStyle.color = style.colour;
      let rgbColour = Tools.hexToRgb(style.colour);
      inputStyle.backgroundColor = this.hovered
        ? `rgba(${rgbColour.r},${rgbColour.g},${rgbColour.b},${style.higlightFillOpacity})`
        : "white";
    }

    let floatingStyle = { pointerEvents: "none", position: "fixed" };
    if (!isDesktopView) {
      floatingStyle.height = "100%";
    }

    const selectedValue = this.selectedItem
      ? this.selectedItem.electorateId
      : isMobileView
      ? "default"
      : "";

    const itemListGridTemplate = {
      gridTemplateRows: "repeat(" + numListRows + ", auto)",
    };
    this.sortedItems.forEach((item) => {
      if (
        item.electorateName.length &&
        !item.electorateName.startsWith(sectionfirstChar)
      ) {
        sectionfirstChar = item.electorateName.charAt(0);
        sortedListItems.push(
          <li key={"h" + sectionfirstChar}>
            <Typography variant="h3">
              {sectionfirstChar}
              <hr />
            </Typography>
          </li>
        );
      }
      sortedListItems.push(
        <li
          key={item.electorateId}
          className={"item"}
          onClick={() => {
            this.handleSelectionChange(item.electorateId);
          }}
        >
          <Typography variant="h6">{item.electorateName}</Typography>
        </li>
      );
    });
    let sectionfirstChar = "";

    const sortedItemsToMenuItems = (visible) =>
      this.sortedItems.map((electorate) => (
        <MenuItem
          key={electorate.electorateId}
          value={electorate.electorateId}
          style={{ display: visible ? null : "none" }}
        />
      ));

    return (
      <div className={classes.selectContainer}>
        <Grid container>
          <Grid item className={classes.titleContainer}>
            <Typography display="inline" variant="h3" className={classes.title}>
              {heading}{" "}
            </Typography>
          </Grid>
          <Grid item xs className={classes.layerSwitchContainer}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={showAll}
                  onChange={this.handleToggleShowAll}
                  name="All"
                  color="secondary"
                  className={classes.toggle}
                />
              }
              label="All"
            />
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  disabled={!showAll}
                  checked={showLabels}
                  onChange={this.handleToggleShowLabels}
                  name="Labels"
                  color="secondary"
                  className={classes.toggle}
                />
              }
              label="Labels"
            />
          </Grid>
        </Grid>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel
              id={"selected-" + title.split(" ").join("") + "-label"}
              className={classes.helperLabel}
            >
              {selectedValue
                ? title + selectedValue
                : placeHolderText || "Select " + name}
            </InputLabel>
            <Select
              labelId={"selected-" + title.split(" ").join("") + "-label"}
              value={selectedValue}
              className={classes.selectMain}
              color="invisible"
              style={inputStyle}
              onMouseOver={this.onMouseOver}
              onMouseLeave={this.onMouseLeave}
              ref={this.selectInfoRef}
              IconComponent={
                this.hasSelection
                  ? () => (
                      <div className={classes.cancelSelectionBlock}>
                        <Divider
                          orientation="vertical"
                          flexItem />
                        <Cancel
                          onClick={this.handleCancelClick}
                          className={classes.iconClass}
                        />
                      </div>
                    )
                  : KeyboardArrowDown
              }
              native={isMobileView}
              disabled={!enabled}
              displayEmpty
              onChange={(event) => {
                if (!isDesktopView)
                  this.handleSelectionChange(event.target.value);
              }}
              MenuProps={
                isDesktopView
                  ? { style: border, classes: { paper: classes.listContainer } }
                  : {}
              }
              renderValue={(v) =>
                (this.sortedItems.find((x) => x.electorateId === v) || {})
                  .electorateName ||
                placeHolderText ||
                "Select " + name
              }
              inputProps={{
                classes: {
                  icon: enabled
                    ? this.hasSelection
                      ? iconStyle
                      : classes.chevron
                    : classes.disabledChevron,
                },
                style: inputStyle,
                name: id + "electorateName",
                id: id,
                disabled: !enabled,
              }}
            >
              {
                //Placeholder content for native selector
                isMobileView && (
                  <option value="default" disabled hidden>
                    {placeHolderText ? placeHolderText : "Select " + name}
                  </option>
                )
              }
              {isMobileView
                ? this.sortedItems.map((electorate) => (
                    <option
                      key={electorate.electorateId}
                      value={electorate.electorateId}
                    >
                      {electorate.electorateName}
                    </option>
                  ))
                : isDesktopView
                ? // Note the customised behaviour for desktop SelectionList here.
                  // We don't actually make a "selection" as vanilla Material UI understands it.
                  // We in fact use a single MenuItem, and custom render all the list items inside it.
                  // But when we do this, as far as the MUI Select is concerned, its only valid selection value is "".
                  // To silence the MUI warning generated when the user makes a selection that is not in its "list",
                  // we add all the potential values as invisible options via `sortedItemsToMenuItems(false)`.
                  [
                    <MenuItem
                      disableRipple
                      value=""
                      key="react-requires-a-unique-key"
                    >
                      <Typography
                        variant="h2"
                        paragraph
                        className={classes.windowTitle}
                      >
                        {title}
                      </Typography>
                      <div
                        onClick={(event) => {
                          if (event) {
                            if (event.target.tagName == "UL") {
                              event.stopPropagation();
                            }
                          }
                        }}
                      >
                        <ul
                          style={itemListGridTemplate}
                          className={classes.itemList}
                        >
                          {sortedListItems}
                        </ul>
                      </div>
                    </MenuItem>,
                    ...sortedItemsToMenuItems(false),
                  ]
                : sortedItemsToMenuItems(true)}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  static propTypes = {
    listItems: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object.isRequired,
    ]),
    name: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    selectedItem: PropTypes.object,
    selectedElectorateType: PropTypes.number,
    enabled: PropTypes.bool,
    placeHolderText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onToggleShowAll: PropTypes.func,
    onToggleShowLabels: PropTypes.func,
    showAll: PropTypes.bool,
    showLabels: PropTypes.bool,
    style: PropTypes.object.isRequired,
    heading: PropTypes.string,
    classes: PropTypes.object,
    appMainStore: PropTypes.object,
  };
}

export default inject("appMainStore")(
  observer(withStyles(styles)(SelectionList))
);
