import { observer, Provider } from "mobx-react";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import LoadingSpinner from "./components/common/app-loading-spinner";
import NavMenu from "./components/common/nav-menu.jsx";
import Header from "./components/header";
import LeftPanel from "./components/left-panel/left-panel";
import MainMap from "./components/map/main-map";
import EBCTheme from "./ebc-theme";
import SessionStore from "./stores/session.store";
import { Tools } from "./tools";

export const stores = new SessionStore();

const Root = observer(
  class Root extends React.Component {
    constructor() {
      super();
      this.setVHCssVar();
      window.addEventListener("resize", this.setVHCssVar);
      if (stores.appMainStore.isOld == null)
        stores.appMainStore.checkSupportedBrowser();
      if (stores.appMainStore.isOld || stores.appMainStore.loadFail) {
        setTimeout(() => {
          window.location.replace("https://vec.vic.gov.au");
        }, 10000);
      }
    }

    setVHCssVar() {
      // This is to get around android and apple browsers lying abouy the view height when the address bar is visible
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    render() {
      const divHeight = Tools.isInternetExplorer()
        ? "100vh"
        : "calc(var(--vh, 1vh) * 100)";
      return stores.appMainStore.isOld | stores.appMainStore.loadFail ? (
        <div>
          {stores.appMainStore.loadFail
            ? "There was an error to loading the map"
            : "You are using an unsupported browser."}
          <br />
          You will be redirected to{" "}
          <a href="https://ebc.vic.gov.au/">https://ebc.vic.gov.au/</a>
          <br />
          If you are not redirected in 10 seconds please click{" "}
          <a href="https://ebc.vic.gov.au">here</a>.
        </div>
      ) : (
        <Provider {...stores}>
          <EBCTheme useDyslexicTheme={stores.appMainStore.IsDyslexicTheme}>
            <div style={{ height: divHeight, display: "flex" }}>
              <LoadingSpinner show={stores.appMainStore.isBusy} />
              <Header />
              <LeftPanel />
              <MainMap />
              <NavMenu open={stores.appMainStore.navMenuOpen} />
            </div>
          </EBCTheme>
        </Provider>
      );
    }
  }
);

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
root.render(<Root />);
