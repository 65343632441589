import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Drawer, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import browseList from "../../../static/images/list.svg";
import showMapKey from "../../../static/images/show-map-key.svg";
import showMap from "../../../static/images/show-map.svg";
import { Tools } from "../../tools";
import AddressSearchBox from "../common/address-search-box/address-search-box";
import ElectorateSelector from "./electorate-selector";
import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
  floatingPanel: {
    position: "fixed",
    top: "88px",
    left: "24px",
    width: "400px",
    maxHeight: "calc(100% - 114px)",
    overflowY: "auto",
    zIndex: 1,
    ["& .inactive"]: {
      backgroundColor: "#f5f5f5",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "auto",
    },
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      left: "0px",
      zIndex: 1,
      backgroundColor: "transparent",
      top: "64px",
      overflow: "visible",
    },
    [theme.breakpoints.down("sm")]: {
      top: "56px",
      maxHeight: "initial",
      width: "100%",
    },
  },
  paperAnchor: {
    marginTop: "65px",
    overflow: "visible",
    background: "transparent",
    border: "none",
  },
  drawerClose: {
    width: "50px",
  },
  expansionDiv: {
    height: "70px",
    width: "32px",
    background: "white",
    boxShadow: "4px 5px 10px 1px #8a8a8a",
  },
  arrowIcon: {
    paddingTop: "23px",
    color: theme.palette.navy.main + " !important",
  },
  tabletGrid: {
    background: "#f5f5f5",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
      height: Tools.isInternetExplorer()
        ? "calc(100vh - 65px)"
        : "calc(var(--vh, 1vh) * 100 - 65px)",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
  desktopContainer: {
    maxHeight: Tools.isInternetExplorer()
      ? "calc(100vh - 163px)"
      : "calc(var(--vh, 1vh) * 100 - 163px)",
    display: "flex",
    flexDirection: "column",
  },
  mobileContainer: {
    height: Tools.isInternetExplorer()
      ? "calc(100vh - 112px)"
      : "calc(var(--vh, 1vh) * 100 - 112px)",
    background: "#f5f5f5",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

const LeftPanel = ({ electoratesStore, mapStore, searchStore }) => {
  const [showBrowseList, setShowBrowseList] = useState(false);
  const [expandedInTablet, setExpandedInTablet] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  useEffect(() => {
    if (searchStore.selectedAddress) {
      searchStore.handleAddressSearch(searchStore.selectedAddress);
    }
    return () => electoratesStore.cleanUp(); // This function is run when LeftPanel unmounts.
  }, []);

  const footerTextOptions = {
    showMap: "Show map",
    showMapKey: "Show map key",
    browseElectorate: "Browse electorates",
  };

  const setupFooter = () => {
    const footer = {};
    if (showBrowseList) {
      footer.Text = footerTextOptions.showMap;
      footer.Icon = showMap;
    } else {
      const selected =
        electoratesStore.selectedRegion ||
        electoratesStore.selectedDistrict ||
        electoratesStore.selectedCouncil ||
        electoratesStore.selectedWard;
      footer.Text = selected
        ? footerTextOptions.showMapKey
        : footerTextOptions.browseElectorate;
      footer.Icon = selected ? showMapKey : browseList;
    }
    return footer;
  };

  const onFooterMenuClick = () => {
    setShowBrowseList(!showBrowseList);
  };

  const toggleExpandedInTablet = () => {
    setExpandedInTablet(!expandedInTablet);
    setDrawerIsOpen(!drawerIsOpen);
  };

  const classes = useStyles();

  const mobileFooter = setupFooter();
  const { selectedAddress } = searchStore;
  return (
    <div className={classes.floatingPanel}>
      {/* Equivalent to <Hidden only={["xs", "lg", "xl", "md"]}> */}
      {Tools.breakpoints.equals("sm") && (
        <Drawer
          open={drawerIsOpen}
          anchor="left"
          onClose={toggleExpandedInTablet}
          variant="permanent"
          classes={{ paper: classes.paperAnchor }}
        >
          <Grid
            container
            direction="column"
            style={{ height: "calc(100% - 65px)" }}
          >
            {drawerIsOpen && (
              <Grid item className={classes.tabletGrid}>
                <AddressSearchBox
                  secondary
                  placeholder="Enter an address"
                  defaultAddress={selectedAddress}
                  sessionToken={mapStore.sessionToken}
                  map={mapStore.map}
                  onSelectionChanged={searchStore.handleAddressSearch}
                />
                <ElectorateSelector />
              </Grid>
            )}
            <Grid item>
              <div className={classes.expansionDiv}>
                <IconButton
                  color="inherit"
                  size="small"
                  className={classes.arrowIcon}
                  onClick={toggleExpandedInTablet}
                  disableRipple
                >
                  {expandedInTablet ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Drawer>
      )}
      {/* Equivalent to <Hidden smUp> */}
      {Tools.breakpoints.equals("xs") && (
        <React.Fragment>
          {showBrowseList ? (
            <div className={classes.mobileContainer}>
              <ElectorateSelector />
            </div>
          ) : (
            <AddressSearchBox
              secondary
              placeholder="Enter an address"
              defaultAddress={selectedAddress}
              sessionToken={mapStore.sessionToken}
              map={mapStore.map}
              onSelectionChanged={searchStore.handleAddressSearch}
            />
          )}
          <Footer
            menuClickHandler={onFooterMenuClick}
            title={mobileFooter.Text}
            icon={mobileFooter.Icon}
          />
        </React.Fragment>
      )}
      {/* Equivalent to <Hidden smDown> */}
      {Tools.breakpoints.up("md") && (
        <div className={classes.desktopContainer}>
          <AddressSearchBox
            secondary
            placeholder="Enter an address"
            defaultAddress={selectedAddress}
            sessionToken={mapStore.sessionToken}
            map={mapStore.map}
            onSelectionChanged={searchStore.handleAddressSearch}
          />
          <ElectorateSelector />
        </div>
      )}
    </div>
  );
};

LeftPanel.propTypes = {
  electoratesStore: PropTypes.object,
  searchStore: PropTypes.object,
  mapStore: PropTypes.object,
};

export default inject(
  "searchStore",
  "mapStore",
  "electoratesStore"
)(observer(LeftPanel));
