import { CircularProgress, Modal } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

class AppLoadingSpinner extends React.Component {
  render() {
    const { show } = this.props;

    return (
      <Modal
        open={show}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "rgba(255,255,255,0.5)" },
          },
        }}
      >
        <CircularProgress
          color="primary"
          thickness={5}
          size={60}
          style={{ outline: "none" }}
        />
      </Modal>
    );
  }
}

AppLoadingSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default AppLoadingSpinner;
