import { Close } from "@mui/icons-material";
import { Drawer, Link, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import EBCLogo from "../../../static/images/ebc.svg";
import InterpreterIcon from "../../../static/images/interpretericon.png";
import { styles } from "./nav-menu.styles.js";

class NavMenu extends React.Component {
  render() {
    const { classes, appMainStore } = this.props;

    return (
      <Drawer
        open={appMainStore.navMenuOpen}
        onClose={this.closeClickHandler}
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerItems}>
          <div className={classes.panelHeaderWrapper}>
            <div className={classes.drawerLogo}>
              <img className={classes.logo} src={EBCLogo} />
              <Typography
                variant="body2"
                color="secondary"
                className={classes.appBarTitle}
                id="menu-title"
              >
                Electoral Boundaries Commission
              </Typography>
            </div>
            <button
              id="btn-drawer-close"
              onClick={this.closeClickHandler}
              className={classes.drawerCloseButton}
            >
              <Close color="secondary" />
            </button>
          </div>

          <div className={[classes.navItems, classes.drawerNavItems].join(" ")}>
            <ul>
              <li id="lst-start-over" className={classes.navItem}>
                <Link
                  target="_blank"
                  href="#"
                  onClick={this.startOverClickHandler}
                  className={classes.navMenuList}
                >
                  Start Over
                </Link>
              </li>
              <li id="lst-vec-home" className={classes.navItem}>
                <Link
                  target="_blank"
                  href="#"
                  onClick={this.ebcHomeClickHandler}
                  className={classes.navMenuList}
                >
                  EBC Home
                </Link>
              </li>
            </ul>
          </div>
          <div className={classes.drawerFooter}>
            <div className={classes.footerIcons}>
              <Link
                target="_blank"
                className={[classes.navItem, classes.footerNavItem].join(" ")}
                href="https://ebc.vic.gov.au/Languages.html"
              >
                <img src={InterpreterIcon} />
              </Link>
            </div>
            <div
              className={[classes.navItems, classes.footerNavItems].join(" ")}
            >
              <ul>
                <li
                  id="lst-privacy"
                  className={[classes.navItem, classes.footerNavItem].join(" ")}
                >
                  <Link
                    target="_blank"
                    className={[classes.navItem, classes.footerNavItem].join(
                      " "
                    )}
                    href="https://ebc.vic.gov.au/Privacy.html"
                  >
                    Privacy
                  </Link>
                </li>
                <li
                  id="lst-legal"
                  className={[classes.navItem, classes.footerNavItem].join(" ")}
                  onClick={this.legalClickHandler}
                >
                  <Link
                    target="_blank"
                    className={[classes.navItem, classes.footerNavItem].join(
                      " "
                    )}
                    href="https://ebc.vic.gov.au/Legal.html"
                  >
                    Legal
                  </Link>
                </li>
                <li
                  id="lst-accessibility"
                  className={[classes.navItem, classes.footerNavItem].join(" ")}
                  onClick={this.accessibilityClickHandler}
                >
                  <Link
                    target="_blank"
                    className={[classes.navItem, classes.footerNavItem].join(
                      " "
                    )}
                    href="https://ebc.vic.gov.au/Accessibility.html"
                  >
                    Accessibility
                  </Link>
                </li>
                <li
                  id="lst-contact"
                  className={[classes.navItem, classes.footerNavItem].join(" ")}
                  onClick={this.contactClickHandler}
                >
                  <Link
                    target="_blank"
                    className={[classes.navItem, classes.footerNavItem].join(
                      " "
                    )}
                    href="https://ebc.vic.gov.au/About/ContactTheEBC.html"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Drawer>
    );
  }

  closeClickHandler = () => {
    this.props.appMainStore.setNavMenuOpen(false);
  };

  startOverClickHandler = () => {
    window.location.reload();
  };

  ebcHomeClickHandler = () => {
    window.location.assign("https://ebc.vic.gov.au/");
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    appMainStore: PropTypes.object,
  };
}

export default inject("appMainStore")(observer(withStyles(styles)(NavMenu)));
