import { action, observable, makeObservable } from "mobx";

export default class SearchStore {
  constructor() {
    makeObservable(this, {
      selectedAddress: observable,
      searchPerformed: observable,
      setSelectedAddress: action,
      setSearchPerformed: action,
      clearSearchAddress: action,
      handleAddressSearch: action.bound
    });


  }

  bindStores({ appMainStore, electoratesStore }) {
    this.appMainStore = appMainStore;
    this.electoratesStore = electoratesStore;
  }

  selectedAddress = null;
  searchPerformed = false;

  setSelectedAddress(place) {
    this.selectedAddress = place;
  }

  setSearchPerformed(value) {
    this.searchPerformed = value;
  }

  clearSearchAddress() {
    this.selectedAddress = null;
  }

  async handleAddressSearch(place) {
    if (place) {
      this.setSelectedAddress(place);
      this.electoratesStore.selectIntersectingElectorates(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
      this.setSearchPerformed(true);
    } else {
      this.setSearchPerformed(false);
    }
  }
}
