import { IconButton, Toolbar, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = ((theme) => ({
    appBar: {
      position: "fixed",
      zIndex: theme.zIndex.tooltip + 1,
      backgroundColor: theme.palette.primary.contrastText,
      bottom: "0",
      left: "0",
      width: "100%",
    },
    menuButton: {
      color: theme.palette.secondary.main,
      width: "calc(100% - 32px)",
    },
    appToolbar: {
      paddingLeft: "0px",
    },
    browseList: {
      paddingRight: "10px",
      paddingLeft: "70px",
    },
    title: {
      color: theme.palette.secondary.main,
      fontWeight: "600",
    },
}));

class Footer extends React.Component {
  render() {
    const { classes, menuClickHandler, title, icon } = this.props;
    return (
      <div className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            id="btn-footer-menu"
            onClick={menuClickHandler}
            edge="start"
            size="large">
            <img className={classes.browseList} src={icon} />
            <Typography variant="h6" className={classes.title}>
              {" "}
              {title}{" "}
            </Typography>
          </IconButton>
        </Toolbar>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  menuClickHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
export default withStyles(styles)(Footer);
