import { IconButton, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import Reload from "../../static/images/reload.svg";

const style = {
  startOver: {
    width: "21px",
    height: "21px",
  },
  toolTipContainer: {
    display: "inline-block",
  },
};

class StartOver extends React.Component {
  handleReload = () => {
    window.location.reload();
  };
  render() {
    const { classes } = this.props;
    return (
      <Tooltip
        id={"id_oz2d8zq2m"}
        key={"uk_umuvgh5sw"}
        title={"Start over"}
        className={classes.toolTipContainer}
      >
        <div>
          <IconButton onClick={this.handleReload} aria-label="Start over button" size="large">
            <img alt="Start over" className={classes.startOver} src={Reload} />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(style)(StartOver);
