export const styles = (theme) => {
  const { spacing } = theme;
  const unit1x = spacing(1);
  const unit2x = spacing(2);
  return {
    autocompleteWrapper: {
      position: "relative",
      padding: "15px",
      height: "55px",
      zIndex: 1,
      backgroundColor: theme.palette.secondary.main,
      ["&.predictionsShown"]: {
        borderColor: theme.palette.grey[300],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      [theme.breakpoints.down("sm")]: {
        height: "40px",
        padding: "12px 16px",
      },
      ['&.isLandingDialog']: {
        backgroundColor: "white",
        borderRadius: "3px",
        border: "solid 2px #b6c3d2",
        padding: "2px 28px",
        height: "66px",
        [theme.breakpoints.down("md")]: {
          marginTop: "47px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "30px",
          height: "55px",
          padding: "0px 20px",
        },
      },
    },
    autocompleteInput: {
      ...theme.components.MuiTypography.styleOverrides.h3,
      textTransform: "none",
      padding: "18px 25px 18px 0px",
      boxSizing: "border-box",
      height: "54px",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      outline: "none",
      border: 0,
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down("sm")]: {
        ...theme.components.MuiTypography.styleOverrides.h5,
        height: "30px",
        fontWeight: "600",
        opacity: "0.5",
        padding: "15px 25px 15px 0px",
      },
      ['&.isLandingDialog']: {
        color: theme.palette.secondary.charcoal + " !important",
        height: "64px",
        backgroundColor: "white",
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "1.89",
        letterSpacing: "0.4px",
        paddingTop: "18px",
        opacity: "0.75",
        ["&::placeholder"]: {
          color: theme.palette.secondary.charcoal,
          opacity: 0.75,
        },
        ["&::-ms-input-placeholder"]: {
          color: theme.palette.secondary.charcoal + " !important",
          opacity: 0.75,
        },
        ["&::-moz-placeholder"]: {
          color: theme.palette.secondary.charcoal + " !important",
          opacity: 0.75,
        },
        [theme.breakpoints.down("md")]: {
          lineHeight: "1.56",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          lineHeight: "1.63",
          height: "53px",
        },
      },
      ["&:-ms-input-placeholder"]: {
        color: theme.palette.secondary.contrastText,
        opacity: 0.5,
      },
      ["&::placeholder"]: {
        color: theme.palette.secondary.contrastText,
        opacity: 0.5,
      },
    },
    autocompletePredictionsWrapper: {
      position: "absolute",
      top: "54px",
      left: 0,
      width: "100%",
      backgroundColor: "white",
      display: "none",
      zIndex: 9999,
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        top: "48px",
      },
    },
    autocompletePredictionsShown: {
      display: "block",
      overflowY: "auto",
      top: "85px",
      [theme.breakpoints.down("md")]: {
        top: "65px",
      },
    },
    autocompleteShowMyLocation: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        alignItems: "center",
      },
      ["& span"]: {
        marginLeft: unit1x,
      },
      ["& svg"]: {
        width: unit2x,
        height: "18px",
      },
    },
    autocompletePrediction: {
      ...theme.components.MuiTypography.styleOverrides.h5,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      padding: "21px 16px",
      color: theme.palette.secondary.charcoal,
      letterSpacing: "0.4px",
      opacity: "0.75",
      fontWeight: 600,
      border: `solid 1px ${theme.palette.grey[300]}`,
      transition: "border-color .25s ease-in",
      cursor: "pointer",
      ["&:last-child"]: {
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      },
      ["&:hover"]: {
        backgroundColor: theme.palette.grey[200],
      },
      ["&.active"]: {
        backgroundColor: theme.palette.grey[200],
      },
    },
    search: {
      right: "0px",
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 999,
    },
    clickable: {
      pointerEvents: "initial",
    },
    closeIcon: {
      cursor: "pointer !important",
      color: `${theme.palette.primary.contrastText} !important`,
      padding: "0px !important",
      ['&.isLandingDialog']: {
        color: `${theme.palette.navy.main} !important`,
      },
    },
    predictionPopover: {
      position: "absolute",
      zIndex: 99999,
      top: "0",
      width: "100%",
      left: "0",
    },
    inputWrapper: {
      fontFamily: theme.typography.fontFamily,
      position: "relative",
      backgroundColor: "inherit",
      border: "none",
      borderBottom: "solid 2px white",
      ["&.predictionsShown"]: {
        borderColor: theme.palette.grey[300],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      "& .Mui-input": {
        transition: theme.transitions.create("width"),
        width: "100%",
        boxSizing: "border-box",
        "&:focus": {
          width: "100%",
          boxSizing: "border-box",
        },
      },
      "&:after": {
        content: '" "',
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: "calc(100% - 85px)",
        width: "60px",
        height: "100%",
      },
      ['&.isLandingDialog']: {
        "&:after": {
          content: "initial",
          display: "none",
        },
      },
    },
    inputGroup: {
      padding: unit2x,
      backgroundColor: "white",
    },
    input: {
      ...theme.components.MuiTypography.styleOverrides.h5,
      font: "inherit",
      padding: `15px`,
      border: 0,
      display: "block",
      verticalAlign: "middle",
      whiteSpace: "normal",
      background: "none",
      margin: 0, // Reset for Safari
      width: "100%",
      ["&:placeholder"]: {
        color: theme.palette.primary.contrastText,
      },
      ["&:-ms-input-placeholder"]: {
        color: theme.palette.primary.contrastText,
      },
      ["&:focus"]: {
        outline: 0,
      },
    },
  };
};
