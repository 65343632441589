import AppMainStore from "./app-main.store";
import ElectoratesStore from "./electorates.store";
import MapStore from "./map.store";
import SearchStore from "./search.store";

export default class SessionStore {
  constructor() {
    this.appMainStore = new AppMainStore();
    this.mapStore = new MapStore();
    this.searchStore = new SearchStore();
    this.electoratesStore = new ElectoratesStore(
      this.appMainStore,
      this.mapStore,
      this.searchStore
    );

    this.appMainStore.bindStores({ electoratesStore: this.electoratesStore });
    this.searchStore.bindStores({
      appMainStore: this.appMainStore,
      electoratesStore: this.electoratesStore,
    });
    this.mapStore.bindStores({
      searchStore: this.searchStore,
      appMainStore: this.appMainStore,
    });
  }
}
