import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Grid, IconButton, Toolbar } from "@mui/material";
import { withStyles } from "@mui/styles";
import { action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import HeaderTitle from "./header-title";
import StartOver from "./start-over";

const styles = ((theme) => ({
    rightNavContainer: {
      textAlign: "right",
      paddingRight: "25px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "0px",
      },
    },
  }));

class Header extends React.Component {
  constructor(props) {
    super(props);

    makeObservable(this, {
      menuClickHandler: action.bound
    });
  }

  menuClickHandler() {
    this.props.appMainStore.setNavMenuOpen(true);
  }

  render() {
    const { classes } = this.props;
    const styling = {
      paddingLeft: "0rem",
    };
    return (
      <React.Fragment>
        <AppBar className={classes.appBar} position="absolute">
          <Toolbar className={classes.appToolbar} disableGutters>
            <Grid container alignItems="center">
              <Grid item sm={2} xs={3}>
                <IconButton
                  color="inherit"
                  aria-label="Menu"
                  id="btn-header-menu"
                  onClick={this.menuClickHandler}
                  size="large">
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item sm={8} xs={6}>
                <HeaderTitle customStyle={styling} />
              </Grid>
              <Grid item sm={2} xs={3} className={classes.rightNavContainer}>
                <StartOver />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  appMainStore: PropTypes.object,
};

export default inject("appMainStore")(observer(withStyles(styles)(Header)));
