import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

const primaryMain = "#F05340";
const secondaryMain = "#0E3A6C";
const baseTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
});
const vecTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#DCEEF6",
          color: secondaryMain,
          "& svg": {
            color: secondaryMain,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "8px 8px 20px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          letterSpacing: "2px !important",
          textTransform: "uppercase",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          letterSpacing: "2px !important",
          textTransform: "uppercase",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "7px 0 7px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
          textTransform: "none",
          minHeight: "50px",
          minWidth: "180px",
          fontWeight: "bold",
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.2px",
          flex: "none",
          padding: "6px 36px",
          alignSelf: "center",
          [baseTheme.breakpoints.down("sm")]: {
            minWidth: "140px",
            padding: "6px 26px",
            marginBottom: "15px",
          },
          [baseTheme.breakpoints.down("xs")]: {
            minWidth: "unset",
            padding: "6px 16px",
            width: "100% !important",
            marginBottom: "15px",
          },
        },
        containedPrimary: {
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: primaryMain,
          },
        },
        outlinedPrimary: {
          color: primaryMain,
        },
        label: {
          textTransform: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#39444F",
          backgroundColor: "#ffffff",
          padding: "15px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ["&::before"]: {
            backgroundColor: "transparent",
          },
          ["&.Mui-expanded"]: {
            marginTop: "8px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px 16px",
          ["&.Mui-expanded"]: {
            minHeight: "initial",
          },
        },
        content: {
          margin: "16px 0px 15px 0px",
          ["&.Mui-expanded"]: {
            margin: "16px 0px 15px 0px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "26px",
        },
        h2: {
          fontSize: "22px",
          fontWeight: 700,
          fontStretch: "normal",
        },
        h3: {
          fontSize: "18px",
          fontWeight: 600,
          fontStretch: "normal",
        },
        h4: {
          fontSize: "19px",
        },
        h5: {
          fontSize: "16px",
        },
        h6: {
          fontSize: "14px",
        },
        subtitle1: {
          fontSize: "14px",
          textTransform: "uppercase",
          weight: 700,
        },
        subtitle2: {
          fontSize: "14px",
          textTransform: "uppercase",
        },
        body1: {
          fontSize: "14px",
        },
        body2: {
          fontSize: "12px",
        },
        [baseTheme.breakpoints.down("sm")]: {
          h1: {
            fontSize: "26px",
          },
          h2: {
            fontSize: "26px",
          },
          h3: {
            fontSize: "22px",
          },
          h4: {
            fontSize: "18px",
          },
          h5: {
            fontSize: "14px",
          },
          h6: {
            fontSize: "12px",
          },
          subtitle1: {
            fontSize: "19px",
            textTransform: "uppercase",
          },
          subtitle2: {
            fontSize: "16px",
            textTransform: "uppercase",
          },
          body1: {
            fontSize: "14px",
          },
          body2: {
            fontSize: "12px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          border: "2px solid #B6C3D2",
          boxSizing: "border-box",
          borderRadius: "3px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#B6C3D2",
        },
        outlined: {
          transform: "translate(14px, 22px) scale(1)",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#DCEEF6",
      main: primaryMain,
      dark: secondaryMain,
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#B6C3D2",
      main: secondaryMain,
      dark: "#0e253a",
      charcoal: "#39444F",
      background: "#DCEEF6",
      contrastText: "#F7F7F7",
    },
    navy: {
      light: "#54A0D2",
      main: secondaryMain,
      dark: "#00396b",
      contrastText: "#F7F7F7",
      azure: "#0899fe",
    },
    grey: {
      100: "#2F2F2F",
      200: "#8F949A",
      300: "#E4E4E4",
      400: "#F3F4F5",
      500: "#C4C4C4",
    },
    red: {
      main: "#EB0000",
      contrastText: "#F7F7F7",
    },
    green: {
      main: "#31BB00",
      contrastText: "#F7F7F7",
    },
    cloudyBlue: "#b6c3d2",
    white: "#FFFFFF",
    invisible: "transparent"
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Poppins", "Roboto", "Arial", "sans-serif"].join(","),
  },
  MuiUseMediaQuery: {
    defaultProps: {
      NoSsr: true, // Since this application does not use Server-Side Rendering, change the default value of NoSsr to true. This will save us a few double-renders around the place. If we use useMediaQuery that is
    },
  },
});

const dyslexicTheme = Object.assign({}, vecTheme, {
  typography: {
    h1: {
      fontSize: "40px",
      fontWeight: "500",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "400",
    },
    h3: {
      fontSize: "22px",
    },
    fontFamily: ["OpenDyslexic", "Arial", "sans-serif"].join(","),
    useNextVariants: true,
  },
});

const dyslexicVecTheme = createTheme(dyslexicTheme);

class VecTheme extends React.Component {
  render() {
    const { useDyslexicTheme } = this.props;
    const activeTheme = useDyslexicTheme ? dyslexicVecTheme : vecTheme;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={activeTheme}>
          <div style={{ fontFamily: activeTheme.typography.fontFamily }}>
            {this.props.children}
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

VecTheme.propTypes = {
  children: PropTypes.object.isRequired,
  useDyslexicTheme: PropTypes.bool.isRequired,
};

export default VecTheme;
