import { action, observable, makeObservable } from "mobx";

export default class Electorate {
  constructor(id, name, electors, deviation, members, geometry) {
    makeObservable(this, {
      electorateId: observable,
      electorateType: observable,
      electorateName: observable,
      properties: observable,
      geometry: observable,
      type: observable,
      toggleHighlight: action
    });


    this.electorateId = id;
    this.properties.id = id;
    this.electorateName = name;
    (this.properties.name = name), (this.properties.electors = electors);
    this.properties.deviation = deviation;
    this.properties.members = members;
    this.geometry = geometry;
  }

  electorateId;
  electorateType = "";
  electorateName;
  properties = {
    name: "",
    members: 1,
    electors: 0,
    deviation: -100,
    colour: "",
    selected: false,
    highlight: false,
    showAll: false,
  };
  geometry = {
    type: "Polygon",
    coordinates: [],
  };
  type = "Feature";

  toggleHighlight(value) {
    if (typeof value === "boolean") {
      this.properties.highlight = value;
    } else {
      this.properties.highlight = !this.properties.highlight;
    }
  }
}
