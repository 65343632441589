import "whatwg-fetch";
import { Tools } from "../tools";

const devMode = false;

class MapContentService {
  getActiveBoundarySets() {
    const url = devMode ? "/dev-data/boundarySets.json" : "/api/boundaryset";
    return fetch(url).then(Tools.getResponseJson);
  }

  getBoundarySetDistricts(boundarySetId) {
    const url = devMode
      ? `/dev-data/districts.${boundarySetId}.json`
      : `/api/boundaryset/${boundarySetId}/districts`;
    return fetch(url).then(Tools.getResponseJson);
  }
  getBoundarySetRegions(boundarySetId) {
    const url = devMode
      ? `/dev-data/regions.${boundarySetId}.json`
      : `/api/boundaryset/${boundarySetId}/regions`;
    return fetch(url).then(Tools.getResponseJson);
  }
}

if (devMode) {
  alert(
    "Using canned development data! Ensure dev mode is off before deploying."
  );
}
const mapContentService = new MapContentService();

export default mapContentService;
