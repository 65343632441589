import bbox from "@turf/bbox";
import { action, makeObservable, observable, runInAction } from "mobx";
import { Tools } from "../tools";
import { melbourneDefaultCenter } from "../utils/constants";

export default class MapStore {
  constructor() {
    makeObservable(this, {
      viewBounds: observable,
      showSearchThisLocationDialog: observable,
      searchThisLocationPlace: observable,
      map: observable,
      mapOverlay: observable,
      sessionToken: observable,
      setGoogleMaps: action,
      updateViewPort: action.bound,
      openSearchThisLocationDialog: action.bound,
      closeSearchThisLocationDialog: action.bound,
      searchLocation: action.bound,
    });
  }

  viewBounds = [];
  showSearchThisLocationDialog = false;
  searchThisLocationPlace = null;
  map = null;
  mapOverlay = null;
  sessionToken = null;

  bindStores = ({ searchStore, appMainStore }) => {
    this.searchStore = searchStore;
    this.appMainStore = appMainStore;
  };

  setGoogleMaps = ({ map }) => {
    this.map = map;
    if (map) {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.mapOverlay = new google.maps.OverlayView();
    } else {
      this.sessionToken = null;
      this.mapOverlay = null;
    }
    this.mapOverlay.setMap(this.map);
  };

  updateViewPort() {
    if (this.map.getDiv().clientWidth > 0) {
      const bounds = this.map.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const n = ne.lat();
        const s = sw.lat();
        let e = ne.lng();
        const w = sw.lng();
        if (e < w) {
          this.viewBounds.replace([
            {
              type: "Polygon",
              coordinates: [
                [
                  [w, n],
                  [180, n],
                  [180, s],
                  [w, s],
                  [w, n],
                ],
              ],
            },
            {
              type: "Polygon",
              coordinates: [
                [
                  [-180, n],
                  [e, n],
                  [e, s],
                  [-180, s],
                  [-180, n],
                ],
              ],
            },
          ]);
        } else {
          this.viewBounds.replace([
            {
              type: "Polygon",
              coordinates: [
                [
                  [w, n],
                  [e, n],
                  [e, s],
                  [w, s],
                  [w, n],
                ],
              ],
            },
          ]);
        }
      }
    }
  }

  openSearchThisLocationDialog(latLng) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK && results[0]) {
        runInAction(() => {
          this.searchThisLocationPlace = results[0];
          this.showSearchThisLocationDialog = true;
        });
      }
    });
  }

  closeSearchThisLocationDialog() {
    this.showSearchThisLocationDialog = false;
  }

  searchLocation() {
    this.showSearchThisLocationDialog = false;
    this.searchStore.handleAddressSearch(this.searchThisLocationPlace);
  }

  getMapCentre = () => {
    const { selectedAddress } = this.searchStore;
    const selectedAddressCoordinates =
      selectedAddress &&
      selectedAddress.geometry &&
      selectedAddress.geometry.location;
    const coordinates = selectedAddressCoordinates && {
      lat: selectedAddressCoordinates.lat(),
      lng: selectedAddressCoordinates.lng(),
    };
    return {
      centre: coordinates || melbourneDefaultCenter,
    };
  };

  fitMapToVCs = (centres) => {
    const bounds = new google.maps.LatLngBounds();
    let viewPortPadding = { bottom: 180, left: 50, right: 80, top: 64 };
    if (Tools.breakpoints.up("md")) {
      viewPortPadding.left = 430;
    }
    const { centre } = this.getMapCentre();
    if (centres.length > 0) {
      bounds.extend(centre);
      centres.forEach((centre) => {
        const coordinates = centre.coordinates;
        if (coordinates) {
          bounds.extend(coordinates);
        }
      });
      setTimeout(() => {
        this.map.fitBounds(bounds, viewPortPadding);
      }, 500);
    }
  };

  zoomAndCentreOnPolygons(polygons) {
    let viewPortPadding = {};
    if (Tools.breakpoints.up("md")) {
      viewPortPadding.left = 430;
    }
    const boundingBox = bbox(polygons);
    this.map.fitBounds(
      {
        west: boundingBox[0],
        south: boundingBox[1],
        east: boundingBox[2],
        north: boundingBox[3],
      },
      viewPortPadding
    );
    this.closeSearchThisLocationDialog(); //This has to be here,if you do it in the search location action IE get confused and the map doesnt know what size it is anymore
  }

  centrePlace(place) {
    let latLngLocation;
    if (place.lat && place.lng) {
      latLngLocation = place;
    } else {
      latLngLocation = place.geometry.location;
    }
    if (Tools.breakpoints.equals("sm") && place) {
      const mapProjection = this.mapOverlay.getProjection();
      let point = mapProjection.fromLatLngToContainerPixel(latLngLocation);
      point.x += -50;
      this.map.setCenter(mapProjection.fromContainerPixelToLatLng(point));
    } else if (place) {
      this.map.setCenter(latLngLocation);
    }
  }

  centerVotingCentre(votingCentre) {
    if (Tools.breakpoints.equals("sm")) {
      const mapProjection = this.mapOverlay.getProjection();
      let point = mapProjection.fromLatLngToContainerPixel(
        new google.maps.LatLng(votingCentre.latitude, votingCentre.longitude)
      );
      point.x += -50;
      this.map.setCenter(mapProjection.fromContainerPixelToLatLng(point));
    } else {
      this.map.setCenter(
        new google.maps.LatLng(votingCentre.latitude, votingCentre.longitude)
      );
    }
  }
}
