import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import centerOfMass from "@turf/center-of-mass";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import mapContent from "../services/map-content.service";
import Electorate from "./electorate";

const electorateToLabel = (el) => {
  let center = centerOfMass(el);
  return {
    id: el.electorateId,
    text: el.electorateName,
    position: {
      lat: center.geometry.coordinates[1],
      lng: center.geometry.coordinates[0],
    },
  };
};

const electorateToElectorateClass = (el) =>
  new Electorate(
    el.id,
    el.name,
    el.electors,
    el.deviation,
    el.members,
    JSON.parse(el.geoJson)
  );

export default class BoundarySet {
  constructor(boundarySet) {
    makeObservable(this, {
      stage: observable,
      districts: observable,
      districtLabels: observable,
      regions: observable,
      regionLabels: observable,
      selectedDistrictIndex: observable,
      selectedRegionIndex: observable,
      showAllRegions: observable,
      showAllRegionLabels: observable,
      showAllDistricts: observable,
      showAllDistrictLabels: observable,
      selectedDistrict: computed,
      selectedRegion: computed,
      loadElectorates: action,
      clearSelection: action,
      selectDistrict: action,
      selectRegion: action,
      selectIntersectingElectorates: action,
      toggleHighlightSelectedRegion: action.bound,
      toggleHighlightSelectedDistrict: action.bound,
      toggleShowAllDistricts: action.bound,
      toggleShowAllDistrictLabels: action.bound,
      toggleShowAllRegions: action.bound,
      toggleShowAllRegionLabels: action.bound,
    });

    this.boundarySetId = boundarySet.id;
    this.boundarySetName = boundarySet.label;
    this.stage = boundarySet.stage;
  }

  stage = 0;
  districts = [];
  districtLabels = [];
  regions = [];
  regionLabels = [];
  selectedDistrictIndex = -1;
  selectedRegionIndex = -1;
  showAllRegions = false;
  showAllRegionLabels = false;
  showAllDistricts = false;
  showAllDistrictLabels = false;

  get selectedDistrict() {
    return this.selectedDistrictIndex > -1 &&
      this.selectedDistrictIndex < this.districts.length
      ? this.districts[this.selectedDistrictIndex]
      : null;
  }
  get selectedRegion() {
    return this.selectedRegionIndex > -1 &&
      this.selectedRegionIndex < this.regions.length
      ? this.regions[this.selectedRegionIndex]
      : null;
  }

  async loadElectorates() {
    return Promise.all([
      mapContent
        .getBoundarySetDistricts(this.boundarySetId)
        .then((districts) => {
          runInAction(() => {
            this.districts.replace(districts.map(electorateToElectorateClass));
          });
        }),
      mapContent.getBoundarySetRegions(this.boundarySetId).then((regions) => {
        runInAction(() => {
          this.regions.replace(regions.map(electorateToElectorateClass));
        });
      }),
    ]);
  }

  clearSelection() {
    this.selectDistrict(-1);
    this.selectRegion(-1);
  }

  selectDistrict(newSelection) {
    if (this.selectedDistrict) {
      this.selectedDistrict.properties.selected = false;
      this.selectedDistrict.toggleHighlight(false);
    }

    let newSelectionIndex;
    if (typeof newSelection === "object") {
      newSelectionIndex = this.districts.indexOf(newSelection);
    } else if (
      !isNaN(newSelection) &&
      newSelection > -1 &&
      newSelection < this.districts.length
    ) {
      newSelectionIndex = newSelection;
    }

    if (newSelectionIndex != -1) {
      this.selectedDistrictIndex = newSelectionIndex;
      if (this.selectedDistrict) {
        this.selectedDistrict.properties.selected = true;
      }
    } else {
      this.selectedDistrictIndex = -1;
    }
  }

  selectRegion(newSelection) {
    if (this.selectedRegion) {
      this.selectedRegion.properties.selected = false;
      this.selectedRegion.toggleHighlight(false);
    }

    let newSelectionIndex;
    if (typeof newSelection === "object") {
      newSelectionIndex = this.regions.indexOf(newSelection);
    } else if (
      !isNaN(newSelection) &&
      newSelection > -1 &&
      newSelection < this.regions.length
    ) {
      newSelectionIndex = newSelection;
    }

    if (newSelectionIndex != -1) {
      this.selectedRegionIndex = newSelectionIndex;
      if (this.selectedRegion) {
        this.selectedRegion.properties.selected = true;
      }
    } else {
      this.selectedRegionIndex = -1;
    }
  }

  selectIntersectingElectorates(lat, lng) {
    let point = { type: "Point", coordinates: [lng, lat] };
    this.districts.some((district, index) => {
      if (booleanPointInPolygon(point, district)) {
        this.selectDistrict(index);
        return true;
      } else {
        return false;
      }
    });
    this.regions.some((district, index) => {
      if (booleanPointInPolygon(point, district)) {
        this.selectRegion(index);
        return true;
      } else {
        return false;
      }
    });
  }

  toggleHighlightSelectedRegion(value) {
    if (this.selectedRegion) {
      this.selectedRegion.toggleHighlight(value);
    }
  }

  toggleHighlightSelectedDistrict(value) {
    if (this.selectedDistrict) {
      this.selectedDistrict.toggleHighlight(value);
    }
  }

  toggleShowAllDistricts() {
    this.showAllDistricts = !this.showAllDistricts;
    if (!this.showAllDistricts) {
      this.showAllDistrictLabels = false;
    }
    this.districts.forEach((district) => {
      district.properties.showAll = this.showAllDistricts;
    });
  }

  toggleShowAllDistrictLabels() {
    this.showAllDistrictLabels = !this.showAllDistrictLabels;
    if (
      this.showAllDistrictLabels &&
      this.districtLabels.length !== this.districts.length
    ) {
      this.districtLabels.replace(this.districts.map(electorateToLabel));
    }
  }

  toggleShowAllRegions() {
    this.showAllRegions = !this.showAllRegions;
    if (!this.showAllRegions) {
      this.showAllRegionLabels = false;
    }
    this.regions.forEach((region) => {
      region.properties.showAll = this.showAllRegions;
    });
  }

  toggleShowAllRegionLabels() {
    this.showAllRegionLabels = !this.showAllRegionLabels;
    if (
      this.showAllRegionLabels &&
      this.regionLabels.length !== this.regions.length
    ) {
      this.regionLabels.replace(this.regions.map(electorateToLabel));
    }
  }
}
