export const KeyPressCodes = {
  Enter: 13,
  Down: 40,
  Up: 38,
};

export const ElectorateType = {
  District: 1,
  Region: 2,
};
