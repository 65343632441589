import Faq from "../../static/images/faq.svg";
import Home from "../../static/images/home.svg";
import StartOver from "../../static/images/start-over.svg";

export const melbourneDefaultCenter = { lat: -36.817741, lng: 144.922896 };
export const vicBoundaryViewportPoints = [
  { coordinates: { lat: -39.196686, lng: 149.87394 } },
  { coordinates: { lat: -33.750823, lng: 149.745857 } },
  { coordinates: { lat: -33.990104, lng: 140.92949 } },
  { coordinates: { lat: -39.147038, lng: 140.790733 } },
];

export const footerNavigationItems = {
  Privacy: {
    label: "Privacy",
  },
  Legal: {
    label: "Legal",
  },
  Accessibility: {
    label: "Accessibility",
  },
  Contact: {
    label: "Contact",
  },
};

export const navigationItems = {
  StartOver: {
    label: "Start Over",
    icon: StartOver,
  },
  EBCHome: {
    label: "EBC Home",
    icon: Home,
  },
  FAQHelp: {
    label: "FAQ & Help",
    icon: Faq,
  },
};

export const ElectorateStyles = {
  regions: [
    { colour: "#9304AD", higlightFillOpacity: 0.5 },
    { colour: "#0080FF", higlightFillOpacity: 0.5 },
  ],
  districts: [
    { colour: "#f05340", higlightFillOpacity: 0.5 },
    { colour: "#6b8094", higlightFillOpacity: 0.3 },
  ],
};

export const ElectorateLabelStyles = {
  regions: [
    { colour: "#9304AD", weight: "700", size: "20pt" },
    { colour: "#0080FF", weight: "700", size: "20pt" },
  ],
  districts: [
    { colour: "#f05340", weight: "700", size: "14pt" },
    { colour: "#6b8094", weight: "700", size: "14pt" },
  ],
};
