import { Tools } from "../../tools";

export const styles = (theme) => {
  const { typography, palette, breakpoints, spacing } = theme;
  const unit7x = spacing(7);
  const unit3x = spacing(3);
  const unit1x = spacing(1);
  return {
    panelHeaderWrapper: {
      display: "flex",
      flexDirection: "row",
      height: "192px",
      minHeight: "192px",
      background: palette.secondary.background,
      [breakpoints.down("sm")]: {
        height: "160px",
        minHeight: "160px",
      },
    },
    drawerItems: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      color: "white",
      height: Tools.isInternetExplorer()
        ? "100vh"
        : "calc(var(--vh, 1vh) * 100) !important",
      overflowY: "auto",
      [breakpoints.up("sm")]: {
        height: "100%",
        maxHeight: Tools.isInternetExplorer()
          ? "100vh"
          : "calc(var(--vh, 1vh) * 100)",
      },
    },
    drawerPaper: {
      width: "400px",
      fontFamily: typography.fontFamily,
      backgroundColor: palette.primary.light,
      [breakpoints.down("sm")]: {
        width: "100%",
        height: "auto !important",
      },
    },
    navItems: {
      ["& ul"]: {
        listStyleType: "none",
        paddingLeft: 0,
      },
    },
    navMenuList: {
      color: palette.primary.contrastText,
      textDecoration: "none !important",
    },
    drawerNavItems: {
      backgroundColor: palette.secondary.main,
      color: palette.primary.contrastText,
      fontWeight: "700",
      minHeight: "228px",
      flex: 1,
      overflowY: "hidden",
      [breakpoints.down("md")]: {
        maxHeight: Tools.isInternetExplorer()
          ? "50vh"
          : "calc(var(--vh, 1vh) * 50)",
      },
      [breakpoints.down("lg")]: {
        maxHeight: Tools.isInternetExplorer()
          ? "calc(30vh - 30px)"
          : "calc(var(--vh, 1vh) * 50 - 30px)",
      },

      ["& ul"]: {
        listStyleType: "none",
        paddingLeft: 0,
        margin: 0,
        ["& li"]: {
          height: "75px",
          borderBottom: "1px solid " + palette.secondary.dark,
        },
      },
    },
    drawerFooter: {
      backgroundColor: palette.secondary.contrastText,
      color: palette.secondary.main,
      ...theme.components.MuiTypography.styleOverrides.h5,
      padding: "22px 0rem 22px 22px",
    },
    drawerLogo: {
      backgroundColor: palette.secondary.background,
      padding: `${unit7x} 0 ${unit7x} 44px`,
      textAlign: "center",
      width: "100%",
      ["& img"]: {
        width: "50.9px",
        height: "46.3px",
      },
      ["& p"]: {
        maxWidth: "124px",
        margin: "auto",
      },
    },
    drawerCloseButton: {
      border: "0",
      display: "inline-flex",
      background: "transparent",
      padding: "20px 15px 0 0",
      alignSelf: "flex-start",
      cursor: "pointer",
      ["& img"]: {
        width: "16px",
        height: "16px",
        cursor: "pointer",
      },
    },
    navItem: {
      ...theme.components.MuiTypography.styleOverrides.h5,
      display: "flex",
      alignItems: "center",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      cursor: "pointer",
      transition: "background-color 0.1s ease-in",
      padding: "0px 24px",
      ["&:hover"]: {
        backgroundColor: palette.secondary.dark,
      },
    },
    footerNavItem: {
      color: palette.secondary.main,
      fontWeight: "normal",
      padding: "0px",
      transition: "opacity 0.1s ease-in",
      textDecoration: "none",
      ["&:hover"]: {
        backgroundColor: "transparent",
        opacity: 0.9,
        textDecoration: "underline",
      },
    },
    footerNavItems: {
      display: "inline-block",
      paddingBottom: unit3x,
      paddingTop: unit1x,
      ["& ul"]: {
        margin: 0,
      },
    },
    footerIcons: {
      paddingBottom: unit3x,
      ["& img"]: {
        width: "40px",
        height: "40px",
        marginRight: unit1x,
        cursor: "pointer",
      },
    },
  };
};
