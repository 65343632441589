import { Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GoogleMap,
  InfoWindow,
  LoadScriptNext,
  Marker
} from "@react-google-maps/api";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import EBCTheme from "../../ebc-theme";
import { Tools } from "../../tools";
import {
  ElectorateLabelStyles,
  melbourneDefaultCenter
} from "../../utils/constants";
import DataLayer from "./data-layer";
import { ZoomControls } from "./main-map.zoom-controls";

const libs = ["geometry", "places"];

const useStyles = makeStyles((theme) => ({
  markerToolTip: {
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  zoomMobileContainer: {
    display: "none",
    position: "absolute",
    bottom: "64px",
    right: "16px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  mobileIconButton: {
    cursor: "pointer",
    backgroundSize: "28px",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "16px",
    backgroundPositionX: "16px",
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.04)",
  },
  searchThisLocationDialog: {
    padding: "5px",
    ["& h5"]: {
      color: "#37444e",
    },
    ["& button"]: {
      fontSize: theme.components.MuiTypography.styleOverrides.h6.fontSize,
      fontWeight: 600,
      color: theme.palette.secondary.main,
      ["& #arrow"]: {
        fontSize: theme.components.MuiTypography.styleOverrides.h4.fontSize,
      },
    },
  },
}));

const mapCentre = melbourneDefaultCenter;

const MainMap = ({ appMainStore, electoratesStore, mapStore, searchStore }) => {
  const [dblClickFired, setDblClickFired] = useState(false);
  const [googleAPILoaded, setGoogleAPILoaded] = useState(false);
  const [isOld, setIsOld] = useState(false);
  const [mapRef, setMapRef] = useState(null);

  const classes = useStyles();

  const flagGoogleAPILoadSucceeded = (success) => {
    setGoogleAPILoaded(success);
    if (success) {
      setIsOld(true);
    } else {
      appMainStore.loadFail = true;
      appMainStore.isOld = true;
    }
  };

  const renderLabels = () => {
    const { boundarySets } = electoratesStore;
    const { selectedAddress } = searchStore;
    const selectedAddressCoordinates =
      selectedAddress &&
      selectedAddress.geometry &&
      selectedAddress.geometry.location;
    const selectedAddressMarker = selectedAddressCoordinates && (
      <Marker
        position={selectedAddressCoordinates}
        clickable={false}
        options={{ disableAutoPan: true }}
      />
    );
    return (
      <React.Fragment>
        {boundarySets.map((boundarySet, bsIndex) => {
          return (
            <React.Fragment key={boundarySet.boundarySetId}>
              {boundarySet.regionLabels.map((label) => {
                return (
                  <Marker
                    key={label.id}
                    position={label.position}
                    icon="/images/transparent-1x1.png"
                    clickable={false}
                    label={{
                      text: label.text,
                      fontFamily: "poppins",
                      fontSize: ElectorateLabelStyles.regions[bsIndex].size,
                      color: ElectorateLabelStyles.regions[bsIndex].colour,
                      fontWeight: ElectorateLabelStyles.regions[bsIndex].weight,
                    }}
                    visible={boundarySet.showAllRegionLabels}
                  />
                );
              })}
              {boundarySet.districtLabels.map((label) => {
                return (
                  <Marker
                    key={label.id}
                    position={label.position}
                    icon="/images/transparent-1x1.png"
                    clickable={false}
                    label={{
                      text: label.text,
                      fontFamily: "poppins",
                      fontSize: ElectorateLabelStyles.districts[bsIndex].size,
                      color: ElectorateLabelStyles.districts[bsIndex].colour,
                      fontWeight:
                        ElectorateLabelStyles.districts[bsIndex].weight,
                    }}
                    visible={boundarySet.showAllDistrictLabels}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
        {selectedAddressMarker}
      </React.Fragment>
    );
  };

  const searchThisLocationDialog = () => {
    const {
      searchThisLocationPlace,
      showSearchThisLocationDialog,
      closeSearchThisLocationDialog,
      searchLocation,
    } = mapStore;
    return showSearchThisLocationDialog ? (
      <Marker position={searchThisLocationPlace.geometry.location}>
        <InfoWindow
          position={searchThisLocationPlace.geometry.location}
          options={{ disableAutoPan: true }}
          onCloseClick={closeSearchThisLocationDialog}
        >
          <div className={classes.searchThisLocationDialog}>
            <Typography variant="h5">
              {searchThisLocationPlace.formatted_address}
            </Typography>
            <Link component="button" onClick={searchLocation}>
              Search this address <span id="arrow">→</span>
            </Link>
          </div>
        </InfoWindow>
      </Marker>
    ) : null;
  };

  const handleMapClick = (event) => {
    setTimeout(() => {
      if (!dblClickFired) {
        mapStore.openSearchThisLocationDialog(event.latLng);
      } else {
        setDblClickFired(false);
      }
    }, 200);
  };

  const addZoomControls = (map, mapStore, appMainStore, isVicVotingCentresAvaliable) => {
    const zoomControlDiv = document.createElement("div");
    zoomControlDiv.index = 1;
    zoomControlDiv.className = "gmaps-zoom-controls";
    const root = ReactDOMClient.createRoot(zoomControlDiv);
    root.render(
      <EBCTheme useDyslexicTheme={appMainStore.IsDyslexicTheme}>
        <ZoomControls
          map={map}
          mapStore={mapStore}
          isVicVotingCentresAvaliable={isVicVotingCentresAvaliable}
        />
      </EBCTheme>
    );
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
  };

  const handleMapDoubleClick = () => setDblClickFired(true);

  const handleSaveMapRef = (ref) => {
    setMapRef(ref);
    if (ref) {
      addZoomControls(ref, mapStore, appMainStore);
      mapStore.setGoogleMaps({
        map: ref
      });
    } else {
      mapStore.setGoogleMaps({
        map: null
      });
    }
  };

  const { boundarySets, getRegionStyleFn, getDistrictStyleFn } =
    electoratesStore;
  return (
    <React.Fragment>
      <LoadScriptNext
        id="script-loader"
        googleMapsApiKey="AIzaSyA6xXnNX2uGox2Knrhjz6_buWS1Tux_xT8"
        channel="EBCSubmissionMap"
        libraries={libs}
        language="en"
        region="AU"
        onError={() => {
          flagGoogleAPILoadSucceeded(false);
        }}
        onLoad={() => {
          flagGoogleAPILoadSucceeded(true);
        }}
      >
        {googleAPILoaded && (
          <GoogleMap
            zoom={8}
            center={mapCentre}
            onLoad={handleSaveMapRef}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              streetViewControl: false,
              zoomControl: false,
              mapTypeControl: Tools.breakpoints.up("sm"),
              mapTypeControlOptions: {
                mapTypeIds: [
                  google.maps.MapTypeId.ROADMAP,
                  google.maps.MapTypeId.SATELLITE,
                ],
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
              },
              draggableCursor: "pointer",
              fullscreenControl: false,
              gestureHandling: "greedy",
              styles: [
                { stylers: [{ lightness: 20 }, { saturation: -80 }] },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ color: "#ffffff" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "poi",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
                },
              ],
            }}
            onClick={handleMapClick}
            onDblClick={handleMapDoubleClick}
          >
            {boundarySets.map((boundarySet, index) => {
              return (
                <React.Fragment key={boundarySet.boundarySetId}>
                  <DataLayer
                    dataSource={boundarySet.regions}
                    map={mapRef}
                    featureId={"id"}
                    style={getRegionStyleFn(index)}
                    visible
                  />
                  <DataLayer
                    dataSource={boundarySet.districts}
                    map={mapRef}
                    featureId={"id"}
                    style={getDistrictStyleFn(index)}
                    visible
                  />
                </React.Fragment>
              );
            })}
            {/* <DataLayer dataSource={wards} map={mapRef} featureId={'id'} style={councilStyle} visible/>
                  <DataLayer dataSource={wards} map={mapRef} featureId={'id'} style={wardStyle} visible/> */}
            {searchThisLocationDialog()}
            {renderLabels()}
          </GoogleMap>
        )}
      </LoadScriptNext>
    </React.Fragment>
  );
};

MainMap.propTypes = {
  searchStore: PropTypes.object,
  mapStore: PropTypes.object,
  appMainStore: PropTypes.object,
  electoratesStore: PropTypes.object,
};

export default inject(
  "appMainStore",
  "mapStore",
  "searchStore",
  "electoratesStore"
)(observer(MainMap));
