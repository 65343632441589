import { action, makeObservable, observable, runInAction } from "mobx";
import BoundarySet from "../classes/boundarySet";
import mapContent from "../services/map-content.service";
import { ElectorateStyles } from "../utils/constants";

export default class ElectoratesStore {
  constructor(appMainStore, mapStore, searchStore) {
    makeObservable(this, {
      boundarySets: observable,
      wards: observable,
      councils: observable,
      districts: observable,
      regions: observable,
      cleanUp: action,
      selectIntersectingElectorates: action,
      getActiveBoundarySets: action,
      setSelectedRegion: action,
      setSelectedDistrict: action,
    });

    this.appMainStore = appMainStore;
    this.mapStore = mapStore;
    this.searchStore = searchStore;
    this.getActiveBoundarySets();
  }

  boundarySets = [];
  wards = [];
  councils = [];
  districts = [];
  regions = [];

  cleanUp() {
    this.boundarySets.forEach((boundarySet) => {
      boundarySet.clearSelection();
    });
  }
  selectIntersectingElectorates = (latitude, longitude) => {
    let zoomObj = { type: "FeatureCollection", features: [] };
    this.boundarySets.forEach((bs) => {
      bs.selectIntersectingElectorates(latitude, longitude);
      if (bs.selectedRegion) {
        zoomObj.features.push(bs.selectedRegion);
      }
      if (bs.selectedDistrict) {
        zoomObj.features.push(bs.selectedDistrict);
      }
    });
    if (zoomObj.features.length != 0) {
      this.mapStore.zoomAndCentreOnPolygons(zoomObj);
    } else {
      this.mapStore.centrePlace({ lat: latitude, lng: longitude });
    }
  };

  getActiveBoundarySets() {
    this.appMainStore.setBusy(true);
    mapContent.getActiveBoundarySets().then((activeBoundarySets) => {
      runInAction(() => {
        this.boundarySets.replace(
          activeBoundarySets
            .sortBy("num", false)
            .map((bs) => new BoundarySet(bs))
        );
        this.appMainStore.setBusy(false);
      });
      this.boundarySets.forEach((bs) => {
        this.appMainStore.setBusy(true);
        bs.loadElectorates().finally(() => {
          this.appMainStore.setBusy(false);
        });
      });
    });
  }

  async setSelectedRegion(selection, boundarySet) {
    if (this.boundarySets[boundarySet]) {
      this.boundarySets[boundarySet].selectRegion(selection);
      if (this.boundarySets[boundarySet].selectedRegion) {
        let zoomObj = {
          type: "FeatureCollection",
          features: [this.boundarySets[boundarySet].selectedRegion],
        };
        this.mapStore.zoomAndCentreOnPolygons(zoomObj);
        this.searchStore.setSelectedAddress(null);
      }
    }
  }

  async setSelectedDistrict(selection, boundarySet) {
    if (this.boundarySets[boundarySet]) {
      this.boundarySets[boundarySet].selectDistrict(selection);
      if (this.boundarySets[boundarySet].selectedDistrict) {
        let zoomObj = {
          type: "FeatureCollection",
          features: [this.boundarySets[boundarySet].selectedDistrict],
        };
        this.mapStore.zoomAndCentreOnPolygons(zoomObj);
        this.searchStore.setSelectedAddress(null);
      }
    }
  }

  getRegionStyleFn(boundarySetIndex) {
    if (boundarySetIndex === 0) {
      return (region) => {
        let highlight = region.getProperty("highlight") === true;
        let selected = region.getProperty("selected") === true;
        let showAll = region.getProperty("showAll") === true;
        const baseColor = ElectorateStyles.regions[0].colour;
        const strokeWidth = 2;
        const strokeOpacity = 1;
        const higlightFillOpacity =
          ElectorateStyles.regions[0].higlightFillOpacity;
        return {
          visible: selected || showAll,
          strokeWeight: strokeWidth,
          zIndex: highlight ? 100 : 82,
          fillColor: baseColor,
          fillOpacity: highlight ? higlightFillOpacity : 0.0,
          strokeColor: baseColor,
          strokeOpacity: strokeOpacity,
          clickable: false,
        };
      };
    } else {
      return (region) => {
        const highlight = region.getProperty("highlight") === true;
        const selected = region.getProperty("selected") === true;
        const showAll = region.getProperty("showAll") === true;
        const baseColor = ElectorateStyles.regions[1].colour;
        const strokeWidth = 6;
        const strokeOpacity = 1;
        const higlightFillOpacity =
          ElectorateStyles.regions[1].higlightFillOpacity;
        return {
          visible: selected || showAll,
          strokeWeight: strokeWidth,
          zIndex: highlight ? 100 : 72,
          fillColor: baseColor,
          fillOpacity: highlight ? higlightFillOpacity : 0.0,
          strokeColor: baseColor,
          strokeOpacity: strokeOpacity,
          clickable: false,
        };
      };
    }
  }

  getDistrictStyleFn(boundarySetIndex) {
    if (boundarySetIndex === 0) {
      return (district) => {
        const highlight = district.getProperty("highlight") === true;
        const selected = district.getProperty("selected") === true;
        const showAll = district.getProperty("showAll") === true;
        const baseColor = ElectorateStyles.districts[0].colour;
        const strokeWidth = 4;
        const strokeOpacity = 1;
        const higlightFillOpacity =
          ElectorateStyles.districts[0].higlightFillOpacity;
        return {
          visible: selected || showAll,
          zIndex: highlight ? 100 : 81,
          fillColor: baseColor,
          fillOpacity: highlight ? higlightFillOpacity : 0,
          strokeColor: baseColor,
          strokeWeight: strokeWidth,
          strokeOpacity: strokeOpacity,
          clickable: false,
        };
      };
    } else {
      return (district) => {
        const highlight = district.getProperty("highlight") === true;
        const selected = district.getProperty("selected") === true;
        const showAll = district.getProperty("showAll") === true;
        const baseColor = ElectorateStyles.districts[1].colour;
        const strokeWidth = 11;
        const strokeOpacity = 1;
        const higlightFillOpacity =
          ElectorateStyles.districts[1].higlightFillOpacity;
        return {
          visible: selected || showAll,
          zIndex: highlight ? 100 : 71,
          fillColor: baseColor,
          fillOpacity: highlight ? higlightFillOpacity : 0,
          strokeColor: baseColor,
          strokeWeight: strokeWidth,
          strokeOpacity: strokeOpacity,
          clickable: false,
        };
      };
    }
  }
}
