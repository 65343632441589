import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import EBCLogo from "../../static/images/ebc.svg";
import { Tools } from "../tools";

const styles = (theme) => {
  const { breakpoints, spacing } = theme;
  return {
    titleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      lineHeight: "64px",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "56px",
      },
    },
    ebcLogo: {
      width: "31px",
      height: "31px",
      objectFit: "contain",
      transform: "translateY(26%)",
    },
    appBarTitle: {
      fontSize: "22px",
      fontWeight: "bold",
      marginLeft: spacing(2),
      [breakpoints.down("md")]: {
        fontSize: "16px",
        marginLeft: spacing(1),
      },
    },
    clickableHeader: {
      cursor: "pointer",
    },
  };
};

class HeaderTitle extends React.Component {
  render() {
    const { classes, customStyle } = this.props;
    const title = Tools.breakpoints.down("xs")
      ? "EBC Map"
      : "Victorian State Electoral Boundaries Map";
    return (
      <div
        className={classes.titleContainer}
        style={{
          paddingLeft: customStyle.paddingLeft,
          textAlign: customStyle.textAlign,
          lineHeight: customStyle.lineHeight,
        }}
      >
        <img className={classes.ebcLogo} src={EBCLogo} alt="EBC logo" />
        <span
          className={classes.clickableHeader}
          onClick={this.titleClickHandler}
        >
          <Typography className={classes.appBarTitle} id="mapheader-title">
            {title}
          </Typography>
        </span>
      </div>
    );
  }

  titleClickHandler = () => {
    window.location.reload();
  };
}

HeaderTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  customStyle: PropTypes.object,
  electionsStore: PropTypes.object,
  appMainStore: PropTypes.object,
};

export default inject("appMainStore")(
  observer(withStyles(styles)(HeaderTitle))
);
